<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex flex-wrap align-items">
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.city" clearable placeholder="市" @change="handleCity">
              <el-option
                  v-for="(item,index) in city"
                  :key="index"
                  :label="item.name" :value="item.name"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.county" clearable placeholder="区县">
              <el-option
                  v-for="(item,index) in county"
                  :key="index"
                  :label="item.name" :value="item.name"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.examineState" clearable>
              <el-option label="审核状态" value=""></el-option>
              <el-option label="待审核" :value="1"></el-option>
              <el-option label="审核通过" :value="2"></el-option>
              <el-option label="审核驳回" :value="3"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.lookState" clearable>
              <el-option label="带看状态" value=""></el-option>
              <el-option label="待看" :value="1"></el-option>
              <el-option label="已带看" :value="2"></el-option>
              <el-option label="已取消" :value="3"></el-option>
            </el-select>
          </div>
          <el-input size="medium" v-model="searchData.phone" :maxlength="100" placeholder="输入手机号" class="marginRight10 marginBottom10 width200"/>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #c280ff;border-color: #c280ff;color: #ffffff" icon="el-icon-receiving" @click="handleImport">批量更新新房已看名单</el-button>
          </div>
<!--          <div class="marginRight10 marginBottom10">-->
<!--            <el-upload-->
<!--                :headers="headers"-->
<!--                action="/api/web/house/look/template/import"-->
<!--                :show-file-list="false"-->
<!--                :on-success="handleSuccess"-->
<!--                :before-upload="beforeUpload"-->
<!--                multiple-->
<!--                type="primary"-->
<!--                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"-->
<!--                :file-list="fileList"-->
<!--            >-->
<!--              <el-button size="medium" style="background: #c280ff;border-color: #c280ff;color: #ffffff" icon="el-icon-receiving">批量更新新房已看名单</el-button>-->
<!--            </el-upload>-->
<!--          </div>-->
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #c280ff;border-color: #c280ff;color: #ffffff" icon="el-icon-download" @click="handleExport">导出</el-button>
          </div>
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button v-if="row.examineState === 1" size="small" type="text" @click="handleExamine(row)">审核</el-button>
        <el-button size="small" type="text" @click="handleView(row)">查看</el-button>
      </template>
    </avue-crud>

    <Examine v-if="show" :show="show" :examine="examine" @submit="submitExamine" @close="closeExamine"/>

    <Import v-if="importShow"
            :show="importShow"
            :action="importAction"
            :downloadLink="importDownloadLink"
            :problemLink="importProblem"
            @close="importClose"/>
  </div>
</template>

<script>
import { list, cityList, countyList } from '@/api/house/look'
import  Import from '@/view/house/components/import'
import qs from "qs";
import Examine from "./examine";
export default {
  name: "index",
  components: {
    Examine,
    Import
  },
  data(){
    return{
      headers: {
        Authorization: this.$store.state.user.Token
      },
      fileList: [],
      city: [],
      county: [],
      searchData: {
        city: '',
        county: '',
        examineState: '',
        lookState: '',
        phone: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '新房看房表单列表',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: '序号',
        indexWidth: 100,
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '新房看房ID',
            prop: 'id',
            width: 300
          },
          {
            label: '客户编号',
            prop: 'code',
            width: 150
          },
          {
            label: '客户姓名',
            prop: 'username',
            width: 150
          },
          {
            label: '客户手机号',
            prop: 'phone',
            width: 150
          },
          {
            label: '预约时间',
            prop: 'subscribeTime',
            width: 150
          },
          {
            label: '城市',
            prop: 'city',
            width: 150
          },
          {
            label: '区县',
            prop: 'county',
            width: 150
          },
          {
            label: '商圈',
            prop: 'business',
            width: 150
          },
          {
            label: '楼盘名称',
            prop: 'name',
            width: 200
          },
          {
            label: '审核状态',
            prop: 'examineState',
            type: 'select',
            dicData: [
              {
                label: '待审核',
                value: 1
              },
              {
                label: '审核通过',
                value: 2
              },
              {
                label: '审核驳回',
                value: 3
              }
            ],
            width: 150
          },
          {
            label: '驳回原因',
            prop: 'rejectReason',
            formatter: (row, column, cellValue) => {
              return cellValue || '/'
            },
            width: 200
          },
          {
            label: '带看状态',
            prop: 'lookState',
            formatter: (row, column, cellValue) => {
              return cellValue === 1 ? '待看' : cellValue === 2 ? '已带看' : cellValue === 3 ? '已取消' : '/'
            },
            width: 150
          }
        ]
      },

      show: false,
      examine: {
        id: ''
      },

      importShow: false,
      importAction: '/api/web/house/look/template/import',
      importDownloadLink: '/api/web/house/look/template/export',
      importProblem: '/api/web/house/look/template/error/export',
    }
  },
  async mounted() {
    await this.getCityList()
  },
  methods: {
    // 数据
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 城市列表
    async getCityList(){
      const { data } = await cityList();
      // console.log(data)
      this.city = data || [];
    },
    // 市列表改变
    async handleCity(val){
      this.searchData.county = '';
      if(val){
        const text = this.city.find(item => item.name === val)
        await this.getCountyList(text.id);
      }else{
        this.county = [];
      }
    },
    async getCountyList(id){
      if(id){
        const { data } = await countyList({
          id: id
        });
        console.log(data)
        this.county = data || [];
      }else{
        this.county = [];
      }
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        city: '',
        county: '',
        examineState: '',
        lookState: '',
        phone: ''
      }
      this.county = [];
      this.onLoad();
    },
    // 下载模板
    handleDownload(){
      // console.log('下载模板')
      window.open('/api/web/house/look/template/export','_self')
    },
    // 批量更新新房已看名单

    // 导入成功
    handleSuccess(res, file) {
      console.log(res,file)
      if(res.code === 200){
        this.$message.success(res.msg)
        this.onLoad();
      }else{
        this.$message.error(res.msg)
      }
    },
    beforeUpload(file) {
      // 文件类型
      const isType = file.type === 'application/vnd.ms-excel';
      const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error('上传文件只能是xls/xlsx格式！');
      } // 文件大小限制为2M
      const fileLimit = file.size / 1024 / 1024 < 20;
      if (!fileLimit) {
        this.$message.error('上传文件大小不超过20M！');
      }
      return fileType && fileLimit;
    },
    // 导出
    handleExport(){
      // console.log('导出')
      window.open(`/api/web/house/look/list/export?${qs.stringify(this.searchData)}`,'_self');
    },
    // 审核
    handleExamine(row){
      // console.log('审核',row)
      this.show = true;
      this.examine = {
        id: row.id
      }
    },
    submitExamine(){
      this.show = false;
      this.onLoad();
    },
    closeExamine(){
      this.show = false;
    },
    // 查看
    handleView(row){
      // console.log('查看',row)
      this.$router.push({
        path: '/house/look/detail',
        query: {
          id: row.id
        }
      })
    },
    handleImport(){
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e){
      this.importShow = false
      if(e === 1 || e === 2){
        this.onLoad()
      }
    },
  }
}
</script>

<style scoped>

</style>