import {get, put} from "@/api/axios";

export const list = (params) => get("/web/house/look/list", params);

// /web/house/look/city/list get
export const cityList = (params) => get("/web/house/look/city/list", params);

// /web/house/look/county/list/{id} get
export const countyList = (params) => get("/web/house/look/county/list/" + params.id, params);

// /web/house/look/detail/{id} get
export const detail = (params) => get("/web/house/look/detail/" + params.id, params);

// /web/house/look/state/update put
export const stateUpdate = (params) => put("/web/house/look/state/update",params)